import http from "./http";
export default (requestId) => ({
  async index(filters) {
    return http
      .index("requests", filters)
      .then((json) => ({ requests: json }))
      .catch(async (e) => ({ requests: null, error: await e }));
  },
  async get() {
    return http
      .get("requests", requestId)
      .then((json) => ({ request: json }))
      .catch(async (e) => ({ request: null, error: await e }));
  },
  async create(data) {
    return http
      .post("requests", data)
      .then((json) => ({ request: json }))
      .catch(async (e) => ({ request: null, error: await e }));
  },
  async update(data) {
    return http
      .put("requests", requestId, data)
      .then((json) => ({ request: json }))
      .catch(async (e) => ({ request: null, error: await e }));
  },
  async delete() {
    return http
      .delete("requests", requestId)
      .then((json) => ({ request: json }))
      .catch(async (e) => ({ request: null, error: await e }));
  },
  Products: (productId) => ({
    async index(filters) {
      return http
        .index(`requests/${requestId}/products`, filters)
        .then((json) => ({ products: json }))
        .catch(async (e) => ({ products: null, error: await e }));
    },
    async get() {
      return http
        .get(`requests/${requestId}/products`, productId)
        .then((json) => ({ product: json }))
        .catch(async (e) => ({ product: null, error: await e }));
    },
    async create(data) {
      return http
        .post(`requests/${requestId}/products`, data)
        .then((json) => ({ product: json }))
        .catch(async (e) => ({ product: null, error: await e }));
    },
    async update(data) {
      return http
        .put(`requests/${requestId}/products`, productId, data)
        .then((json) => ({ product: json }))
        .catch(async (e) => ({ product: null, error: await e }));
    },
    async delete() {
      return http
        .delete(`requests/${requestId}/products`, productId)
        .then((json) => ({ product: json }))
        .catch(async (e) => ({ product: null, error: await e }));
    },
  }),
});
